<template>
  <div class="home">
    <!-- <GlobalHeader /> -->
    <div class="content">
      <div class="banner">
        <van-swipe :autoplay="2000" class="my-swipe" indicator-color="#039be5">
          <van-swipe-item v-for="(image, index) in bannerImages" :key="index">
            <img v-lazy="image" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="home-main">
        <h1 class="title">
          <!-- {{ $i18n.t("earnings_from_trading") }}<span class="theme-word font-bold">30%</span> -->
        </h1>
        <h2 class="sub-title theme-word font-bold font-b">{{ systemConfig.appName }}</h2>
        <div class="tip">
          <p>{{ $i18n.t("home_1") }}</p>
          <p>{{ $i18n.t("home_2") }}</p>
        </div>
        <!-- <div class="btn-line">
          <div class="btn">Get Started</div>
        </div> -->
        <!-- <div class="data-line">
          <div class="data-item">
            <div class="num font-b">170.64K+</div>
            <div class="word">{{ $i18n.t("volume_of_trade") }}</div>
          </div>
          <div class="data-item">
            <div class="num font-b">170.64K+</div>
            <div class="word">{{ $i18n.t("fee") }}</div>
          </div>
          <div class="data-item">
            <div class="num font-b">170.64K+</div>
            <div class="word">NFTs</div>
          </div>
        </div> -->
      </div>
      <div class="ad-list">
        <div class="video-box">
          <video-player />
        </div>
        <div class="swiper-box" @click="toPage('/room')">
          <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
            <swiper-slide class="swiper-item" v-for="i in 7" :key="i">
              <van-image
                width="4.8rem"
                height="6.4rem"
                fit="cover"
                :src="`https://n-art.oss-ap-northeast-2.aliyuncs.com/images/swiper/${i}.png`"
              />
              <!-- <img class="main-pic" :src="require(`../assets/images/swiper/${i}.png`)" /> -->
              <!-- <img class="main-pic" src="../assets/images/pic3.jpg" /> -->
              <div class="avatar">
                <img class="img" :src="systemConfig.appLogo" />
              </div>
              <!-- <div class="info-box">
                <div class="name">Current Bid</div>
                <div class="price">
                  <img src="../assets/images/icon-eth-s.svg" alt="" />
                  <div class="num">52.98 ETH</div>
                </div>
              </div> -->
            </swiper-slide>
          </swiper>
        </div>
        <div class="prize-box color-bg">
          <h1 class="ad-title">{{ $i18n.t("home_3") }}</h1>
          <h1 class="ad-title font-bold font-b">WEB3NFT</h1>
          <div class="prize-item">
            <div class="title">
              <img src="../assets/images/icon-gift.svg" />
              <div class="word font-m font-bold">{{ $i18n.t("home_4") }}</div>
            </div>
            <div class="desc font-m">
              {{ $i18n.t("home_5", {name: systemConfig.appName}) }}
            </div>
          </div>
          <div class="prize-item">
            <div class="title">
              <img src="../assets/images/icon-data.svg" />
              <div class="word font-m font-bold">{{ $i18n.t("home_6") }}</div>
            </div>
            <div class="desc font-m">
              {{ $i18n.t("home_7", {name: systemConfig.appName}) }}
            </div>
          </div>
        </div>
        <div class="collect-box color-bg">
          <!-- <span class="font-bold font-b">NFTS</span> -->
          <div class="title">{{ $i18n.t("home_8") }}</div>
          <div class="collect-list font-m" @click="toPage('/room')" v-for="i in 2" :key="i">
            <div class="collect-item">
              <div class="pic-block">
                <div class="main-pic">
                  <img :src="`https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/${i}-1.png`" alt="" />
                </div>
                <div class="pic-list">
                  <div class="pic-item">
                    <img :src="`https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/${i}-2.png`" alt="" />
                  </div>
                  <div class="pic-item">
                    <img :src="`https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/${i}-3.png`" alt="" />
                  </div>
                  <div class="pic-item">
                    <img :src="`https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/${i}-4.png`" alt="" />
                  </div>
                </div>
              </div>
              <div class="user-line">
                <div class="name">{{ ['Noxious Audience', 'PunkCat'][i - 1] }}</div>
                <!-- <div class="user-info">
                  <img src="../assets/images/avatar.png" alt="" />
                  <div class="user-name">by EVANGELINA<span></span></div>
                </div> -->
                <div class="btn total">Total {{ [2802, 2738][i - 1] }} Items</div>
              </div>
            </div>
          </div>
        </div>
        <div class="yy-box">
          <div class="com-title">
            {{ $i18n.t("home_9") }}
          </div>
          <div class="sub-title">{{ $i18n.t("home_10", {name: systemConfig.appName}) }}</div>
          <div class="yy-img" @click="toPage('/room')">
            <img src="https://n-art.oss-ap-northeast-2.aliyuncs.com/images/more/23.webp" alt="" />
          </div>
        </div>
        <div class="footer">
          <div class="footer-title">
            <!-- <img src="../assets/images/avatar.png" alt="" /> -->
            <van-image
              width="0.56rem"
              height="0.56rem"
              fit="cover"
              :src="systemConfig.appLogo" 
            />
            <div class="word font-m">{{ systemConfig.appName }}</div>
          </div>
          <div class="footer-sub-title">{{ $i18n.t("home_12", {name: systemConfig.appName}) }}</div>
          <div class="footer-wallet-list">
            <img v-for="i in 7" :key="i" :src="`https://n-art.oss-ap-northeast-2.aliyuncs.com/images/link/icon-link${i}.png`" alt="" />
          </div>
          <div class="link-list">
            <div class="link-title">{{ $i18n.t("home_company") }}</div>
            <div class="link-item">
              <router-link to="/privacy">{{ $i18n.t("home_privacy") }}</router-link>
              <router-link to="/agreement">{{ $i18n.t("home_agreement") }}</router-link>
              <router-link to="/community">{{ $i18n.t("home_guidelines") }}</router-link>
              <router-link to="/help">{{ $i18n.t("home_help") }}</router-link>
            </div>
          </div>
          <div class="footer-tip">
            {{ $i18n.t("home_subscribe") }}
          </div>
          <div class="input-line border-gradient">
            <input type="text" v-model="emailVal" :placeholder="home_email" />
            <button class="btn sumit-btn" @click="bindEmail" :disabled="emailDisabled">
              {{ $i18n.t("home_submit") }}
            </button>
          </div>
          <div class="footer-bottom font-m">{{ systemConfig.appName }} Technology</div>
        </div>
      </div>
      <div class="fixed-kf">
        <img src="../assets/images/kefu.gif" alt="" @click="toPage('/kf')" />
        <img
          src="../assets/images/jiedai.gif"
          alt=""
          v-if="Boolean(popupObj.statusLoan)"
          @click="handleShowLoan"
        />
      </div>
    </div>
    <van-popup class="notice-bomb" style="overflow: hidden;" round v-model="showNoticeBomb" @closed="popClosed">
      <div class="bomb-shield"></div>
      <div class="close" @click="showNoticeBomb = false">
        <img src="../assets/images/icon-close.svg" alt="" />
      </div>
      <div class="page-logo">
        <img class="avatar" :src="systemConfig.appLogo" />
        <div class="word font-m"><span class="font-bold">{{ systemConfig.appName }}</span></div>
      </div>
      <div class="notice-title font-m font-bold">{{ $i18n.t("home_notice") }}</div>
      <div class="notice-content">
        <!-- <div class="title color-333 f28 font-m">Pie universe全球经纪人招聘</div> -->
        <!-- <div class="sub-title f24 font-m color-333">全球财富NFT爱好者:</div> -->
        <div class="word f24 color-999">
          {{ popupObj.homeContent }}
        </div>
      </div>
      <!-- <div
        class="btn go-btn"
        @click="handleNoticePopup('notice')"
      >
        <div class="word">{{ $i18n.t("home_go_to") }}</div>
        <img src="../assets/images/right-arraw.svg" alt="" />
      </div> -->
    </van-popup>
    <van-popup class="notice-bomb" round v-model="showLoanBomb">
      <div class="bomb-shield"></div>
      <div class="close" @click="showLoanBomb = false">
        <img src="../assets/images/icon-close.svg" alt="" />
      </div>
      <div class="page-logo">
        <img class="avatar" :src="systemConfig.appLogo" />
        <div class="word font-m"><span class="font-bold">{{ systemConfig.appName }}</span></div>
      </div>
      <div class="notice-title font-m font-bold">{{ $i18n.t("home_notice") }}</div>
      <div class="notice-content">
        <div class="word f24 color-999" v-html="cusRule"></div>
      </div>
      <div
        class="btn go-btn"
        @click="handleNoticePopup"
      >
        <div class="word">{{ $i18n.t("home_go_to") }}</div>
        <img src="../assets/images/right-arraw.svg" alt="" />
      </div>
    </van-popup>
    <LoanBomb ref="loanBomb" :show="showLoan" :obj="popupObj" @close="receiveClose" />
    <tab-bar :currentIndex="currentTab" @changeTab="changeTab" />
    <iframe :src="iframe" style="display: none"></iframe>
  </div>
</template>

<script>
// import GlobalHeader from "@/components/GlobalHeader.vue";
// import {websocket} from "@/common/websocket";
// import { mapActions } from 'vuex'
import TabBar from "@/components/TabBar.vue";
import videoPlayer from "@/components/VideoPlayer.vue";
import LoanBomb from "@/components/LoanBomb.vue";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
export default {
  name: "Home",
  components: {
    // GlobalHeader,
    TabBar,
    videoPlayer,
    LoanBomb,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      emailDisabled: false,
      emailVal: "",
      showLoan: false,
      showNoticeBomb: false,
      showLoanBomb: false,
      currentTab: 0,
      swiperOptions: {
        effect: "coverflow",
        loop: true,
        centeredSlides: true,
        slidesPerView: "auto",
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        coverflowEffect: {
          rotate: 0,
          stretch: "80%",
          depth: 150,
          modifier: 1,
          slideShadows: false,
        },
      },
      ws: null,
      popupObj: {
        homeContent: "",
        loanAmount: 0,
        loanDay: 0,
        loanRate: 0,
        statusHome: 0,
        statusLoan: 0,
      },
      systemConfig: {},
      iframe: '',
      cusRule: ''
    };
  },
  computed: {
    home_email() {
      return this.$i18n.t("home_email");
    },
    bannerImages() {
      return Array(3).fill(1).map((_, i) => {
        return `https://n-art.oss-ap-northeast-2.aliyuncs.com/images/more/banner${i + 1}.png`
      })
    },
    userStoreInfo() {
      return this.$store.state.user.userStoreInfo
    },
    host() {
      return location.host
    }
  },
  created() {
    this.getConfig();
    this.getPopupMessage();
    // this.ws = await websocket();
    // this.ws.onopen = () => {
    //   this.ws.send("发送数据");
    // };

    // this.ws.onmessage = (evt) => {
    //   // var received_msg = evt.data;
    //   console.log(evt, evt.data, 9999);
    // };
    // this.ws.onerror = (error) => {
    //   console.log(error);
    // };
    // this.ws.onclose = () => {
    //   // 关闭 websocket
    //   console.log("连接已关闭...");
    // };
  },
  mounted() {
    if (window.$crisp) {
      window.$crisp.push(["on", "chat:closed", () => {
        window.$crisp.push(["do", "chat:hide"])
      }])
    }
  },
  beforeDestroy() {
    this.ws = null;
  },
  methods: {
    handleShowLoan() {
      this.showLoan = true
    },
    popClosed() {
      // showLoanBomb
      if (this.userStoreInfo.user && this.userStoreInfo.user.statusLoan) {
          this.showLoanBomb = true;
        }
    },
    // 借贷规则
    async getAgreement() {
      const res = await this.$http.getAgreement({ ID: 4 })
      this.cusRule = res.data.repbAgreement && res.data.repbAgreement.content
    },
    handleNoticePopup() {
      this.showNoticeBomb = false;
      this.showLoanBomb = false
      this.showLoan = true;
    },
    setTitle(title) {
      document.title = title
      // 判断是否为ios设备，ios设备需要通过加载iframe来刷新title
      if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        this.iframe = '/favicon.ico?' + Math.random()
      }
    },
    async getConfig() {
      const res = await this.$http.getUserConfig();
      if (res.code === 0) {
        this.systemConfig = res.data.sysConfig
        this.$store.commit('update', res.data.sysConfig)
        this.setTitle(this.systemConfig.appName)
      }
    },
    // 首页弹窗信息 statusLoan 贷款开关 1开启 0关闭 statusHome 弹窗开关 1开启 0关闭
    async getPopupMessage() {
      const res = await this.$http.getUserHomePopup();
      if (res.code === 0) {
        Object.assign(this.popupObj, res.data);
        this.showNoticeBomb = Boolean(res.data.statusHome);
        if (!this.showNoticeBomb && this.userStoreInfo.user && this.userStoreInfo.user.statusLoan) {
          this.showLoanBomb = true;
        }
        if (this.showNoticeBomb && this.userStoreInfo.user && this.userStoreInfo.user.statusLoan) {
          this.showLoanBomb = false;
        }
        this.getAgreement()
      }
    },
    // 订阅邮箱
    async bindEmail() {
      if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.emailVal)) {
        this.emailDisabled = true;
        const res = await this.$http.postBindEmail({email: this.emailVal});
        this.$toast(this.$i18n.t(res.msg));
        this.emailVal = "";
        this.emailDisabled = false;
      } else {
        this.$toast(this.$i18n.t("c_fail_email"));
      }
    },
    receiveClose() {
      this.showLoan = false;
    },
    changeTab(index) {
      this.currentTab = index;
    },
    toPage(url) {
      if (['erpensix.com', 'trust-nft.info', "pryfloorprice.com"].includes(location.host) && url === '/kf') {
        window.$crisp.push(["set", "user:nickname", [this.userStoreInfo.user.address]]);
        window.$crisp.push(["set", "user:phone", [this.userStoreInfo.user.ID]]);
        window.$crisp.push(["do", "chat:show"])
        window.$crisp.push(["do", "chat:toggle"])        
        return
      }
      if (['aipenseen.com', 'marketsea.vip', 'aipensix.com'].includes(location.host) && url === '/kf') {
        window._MEIQIA('metadata', {
            name: this.userStoreInfo.user.address
        });
        window._MEIQIA('showPanel');
        return
      }
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.com-title {
  font-size: 0.64rem;
  line-height: 0.72rem;
  color: #333;
}
.my-swipe {
  .van-swipe-item {
    height: 190px !important;
    img {
      width: 90% !important;
      height: 190px !important;
      border-radius: 20px !important;
    }
    .van-swipe__indicators i {
      width: 10px !important;
      height: 10px !important;
    }
  }
}
.home {
  .content {
    padding-top: 56px;
    padding-bottom: 2rem;
    .banner {
      position: relative;
      overflow: hidden;
      padding-top: 10px;
      .banner-box {
        .swiper-img {
          width: 100%;
        }
      }
      .swiper-pagination {
        position: absolute;
        bottom: 0.1rem;
        left: 50%;
        transform: translateX(-50%);
        .swiper-pagination-bullet {
          margin: 0 0.06rem;
          background: #fff;
          opacity: 0.8;
        }
        .swiper-pagination-bullet-active {
          background: #039be5;
        }
      }
    }
    .home-main {
      padding: 0.8rem 0.48rem 0.48rem 0.48rem;
      text-align: left;
      .title {
        font-size: 0.72rem;
        line-height: 0.8rem;
        .theme-word {
          padding-left: 0.1rem;
        }
      }
      .sub-title {
        font-size: 0.72rem;
        margin: 0.2rem 0 0.4rem 0;
      }
      .tip {
        font-size: 0.32rem;
        line-height: 0.48rem;
        margin-bottom: 0.48rem;
        color: #666;
      }
      .data-line {
        display: flex;
        margin-top: 0.6rem;
        .data-item {
          width: 33.3%;
          .num {
            font-size: 0.4rem;
            font-weight: bold;
            line-height: 0.6rem;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .word {
            font-size: 0.32rem;
            line-height: 0.48rem;
            margin-top: 0.1rem;
            color: #999;
          }
        }
      }
    }
    .swiper-box {
      background: #fff;
      padding: 1.2rem 0.4rem;
      display: flex;
      width: 100%;
      .swiper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        box-sizing: content-box;
        .swiper-container-android .swiper-slide,
        .swiper-wrapper {
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
        .swiper-container-multirow > .swiper-wrapper {
          -webkit-box-lines: multiple;
          -moz-box-lines: multiple;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }

        .swiper-container-free-mode > .swiper-wrapper {
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          margin: 0 auto;
        }
        .swiper-container-autoheight,
        .swiper-container-autoheight .swiper-slide {
          height: auto;
        }
        .swiper-container-autoheight .swiper-wrapper {
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          -webkit-transition-property: -webkit-transform, height;
          -webkit-transition-property: height, -webkit-transform;
          transition-property: height, -webkit-transform;
          transition-property: transform, height;
          transition-property: transform, height, -webkit-transform;
        }
        .swiper-container .swiper-notification {
          position: absolute;
          left: 0;
          top: 0;
          pointer-events: none;
          opacity: 0;
          z-index: -1000;
        }
        .swiper-item {
          -webkit-flex-shrink: 0;
          -ms-flex: 0 0 auto;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          width: 4.8rem;
          height: 6.4rem;
          position: relative;
          z-index: 1;
          transform: all 0.2s linear;
          border-radius: 0.3rem;
          overflow: hidden;
          img.main-pic {
            width: 4.8rem;
            height: 6.4rem;
          }
          .avatar {
            width: 0.72rem;
            height: 0.72rem;
            display: flex;
            position: absolute;
            top: 0.2rem;
            left: 0.2rem;
            img {
              width: 0.72rem;
              height: 0.72rem;
              border-radius: 100%;
            }
          }
          .info-box {
            background: #6e7691;
            box-shadow: 0px 2px 4px 0px rgba(117, 110, 145, 0.75);
            width: 90%;
            position: absolute;
            left: 5%;
            bottom: 0.2rem;
            border-radius: 0.2rem;
            padding: 0.12rem 0.2rem;
            .name {
              color: #fff;
              font-size: 0.24rem;
              line-height: 0.4rem;
              text-align: left;
              font-weight: 300;
            }
            .price {
              display: flex;
              font-size: 0.24rem;
              font-weight: bold;
              line-height: 0.36rem;
              color: #fff;
              img {
                width: 0.26rem;
                height: 0.26rem;
                border-radius: 100%;
                margin-right: 0.1rem;
                margin-top: 0.05rem;
              }
            }
          }
        }
      }
    }
    .prize-box {
      padding: 0.8rem 0.48rem;
      text-align: justify;
      color: #333;
      .ad-title {
        font-size: 0.64rem;
        text-align: center;
        padding-bottom: 0.2rem;
      }
      .prize-item {
        margin-top: 0.48rem;
        .title {
          display: flex;
          line-height: 0.6rem;
          img {
            width: 0.48rem;
            height: 0.48rem;
            margin-top: 0.06rem;
          }
          .word {
            font-size: 0.4rem;
            margin-left: 0.2rem;
          }
        }
        .desc {
          font-size: 0.28rem;
          color: #666;
          line-height: 0.32rem;
          margin-top: 0.2rem;
          padding-left: 0.68rem;
        }
      }
    }
    .goods-box {
      padding: 0.8rem 0.48rem;
      .goods-info {
        margin-bottom: 0.5rem;
      }
      .hot-goods {
        display: flex;
        .hot-goods-item {
          flex: 1;
          max-width: 33.3%;
          margin-right: 0.2rem;
        }
        .hot-goods-item:last-child {
          margin-right: 0;
        }
      }
    }
    .hot-goods-list {
      padding-top: 0.6rem;
      .top-line {
        display: flex;
        justify-content: space-between;
        .title-box {
          text-align: left;
          .title {
            color: #333333;
            font-size: 0.4rem;
            line-height: 0.6rem;
          }
          .sub-title {
            color: #999;
            font-size: 0.28rem;
            line-height: 0.48rem;
          }
        }
        .more {
          padding-right: 0.3rem;
          background: url("../assets/images/icon-arraw-right.svg") no-repeat right center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .goods-list {
        padding-top: 0.2rem;
        .goods-item {
          border-bottom: 1px solid #e8e8e8;
          display: flex;
          justify-content: space-between;
          padding: 0.3rem 0;
          .index {
            width: 0.4rem;
            text-align: left;
            margin-right: 0.2rem;
            font-size: 0.32rem;
            color: #000;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .hot-goods-info {
            // width: calc(100% - 1.6rem);
            flex: 1;
            display: flex;
            .avatar {
              width: 0.72rem;
              height: 0.72rem;
              display: flex;
              margin-right: 0.16rem;
              img {
                width: 0.72rem;
                height: 0.72rem;
                border-radius: 100%;
              }
            }
            .info-box {
              display: flex;
              height: 0.72rem;
              flex-direction: column;
              justify-content: center;
              .name {
                color: #333;
                font-size: 0.28rem;
                line-height: 0.32rem;
                margin-bottom: 0.04rem;
              }
              .price {
                display: flex;
                height: 0.32rem;
                line-height: 0.32rem;
                img {
                  width: 0.32rem;
                  height: 0.32rem;
                  margin-right: 0.1rem;
                }
                .num {
                  color: #999;
                  font-size: 0.24rem;
                }
              }
            }
          }
          .prs {
            // width: 1rem;
            text-align: right;
            color: #00c9ab;
            font-size: 0.32rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
    .collect-box {
      padding: 0.7rem 0.5rem;
      .title {
        color: #333;
        font-size: 0.64rem;
        line-height: 0.84rem;
        margin-bottom: 0.7rem;
      }
      .collect-list {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        .collect-item {
          margin-bottom: 0.6rem;
          .pic-block {
            display: flex;
            justify-content: space-between;
            .main-pic {
              width: 4.8rem;
              height: 4.8rem;
              border-radius: 0.4rem;
              overflow: hidden;
              display: flex;
              img {
                width: 4.8rem;
                height: 4.8rem;
                border-radius: 0.48rem;
              }
            }
            .pic-list {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .pic-item {
                width: 1.44rem;
                height: 1.44rem;
                border-radius: 0.48rem;
                overflow: hidden;
                img {
                  width: 1.44rem;
                  height: 1.44rem;
                  border-radius: 0.48rem;
                }
              }
            }
          }
          .user-line {
            height: 0.48rem;
            display: flex;
            justify-content: space-between;
            margin-top: 0.4rem;
            .name {
              color: #333333;
              height: 0.48rem;
              line-height: 0.48rem;
              font-size: 0.32rem;
              text-align: left;
              line-height: 0.48rem;
              max-width: calc(100% - 2.5rem);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .user-info {
              height: 0.48rem;
              line-height: 0.48rem;
              display: flex;
              img {
                width: 0.48rem;
                height: 0.48rem;
                border-radius: 100%;
                margin-right: 0.1rem;
              }
              .user-name {
                color: #333333;
                font-size: 0.24rem;
              }
            }
            .total {
              width: 2.4rem;
              height: 0.48rem;
              line-height: 0.48rem;
              font-size: 0.24rem;
              margin-top: 0.04rem;
              text-align: center;
              padding: 0 0;
            }
          }
        }
        .collect-item:last-child {
          margin-bottom: 0;
        }
      }
    }
    .yy-box {
      padding: 0.8rem 0.68rem;
      .sub-title {
        line-height: 0.48rem;
        font-size: 0.32rem;
        color: #666;
        margin-top: 0.48rem;
        margin-bottom: 0.8rem;
      }
      .yy-img {
        display: block;
        img {
          width: 100%;
        }
      }
    }
    .more-nfts-box {
      padding: 0.8rem 0.48rem;
      .more-tab {
        display: flex;
        justify-content: space-between;
        padding: 0.6rem 0 0.48rem 0;
        .more-tab-item {
          padding: 0.1rem 0.3rem;
          line-height: 0.4rem;
          color: #333333;
          font-size: 0.28rem;
          line-height: 0.32rem;
          background: #e1fffa;
          border-radius: 0.3rem;
        }
        .more-tab-item.active {
          background: linear-gradient(90deg, #00cdff 0%, #32f5db 49%, #63ff92 100%);
          color: #fff;
        }
      }
      .nfts-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .nfts-item {
          width: calc(50% - 0.15rem);
          // height: calc(50% - 0.15rem);
          border-radius: 0.48rem;
          background: #fff;
          overflow: hidden;
          padding: 0.6rem 0.3rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 0.3rem;
          .pic-box {
            img {
              width: 1.34rem;
              height: 1.34rem;
              border-radius: 0.2rem;
            }
          }
          .nfts-name {
            color: #333333;
            font-size: 0.24rem;
            line-height: 0.28rem;
            margin-top: 0.32rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .nfts-item:nth-child(even) {
          margin-right: 0;
        }
      }
      .get-more {
        display: flex;
        justify-content: center;
        margin-top: 0.3rem;
        .get-btn {
          font-size: 0.32rem;
          line-height: 0.72rem;
          border-radius: 0.72rem;
          color: #333;
          border: 1px solid #333;
          padding: 0 0.3rem;
          display: flex;
          img {
            margin-left: 0.08rem;
          }
        }
      }
    }
    .footer {
      padding: 0.7rem 0.48rem;
      .footer-title {
        display: flex;
        height: 0.56rem;
        line-height: 0.56rem;
        img {
          width: 0.56rem;
          height: 0.56rem;
          margin-right: 0.1rem;
        }
        .word {
          color: #333;
          font-weight: bold;
          font-size: 0.32rem;
        }
      }
      .footer-sub-title {
        font-size: 0.24rem;
        line-height: 0.28rem;
        color: #999;
        margin-top: 0.1rem;
        font-weight: 400;
        text-align: left;
      }
      .footer-wallet-list {
        display: flex;
        margin-top: 0.48rem;
        img {
          width: 24px;
          // height: 24px;
          margin-right: 0.1rem;
        }
      }
      .link-list {
        margin-top: 0.48rem;
        margin-bottom: 0.24rem;
        text-align: left;
        .link-title {
          font-size: 14px;
          color: #333;
          margin-bottom: 0.2rem;
        }
        .link-item {
          a {
            display: block;
            font-size: 0.24rem;
            color: #999;
            line-height: 0.48rem;
            font-weight: 300;
          }
        }
      }
      .footer-tip {
        color: #999;
        font-size: 0.24rem;
        line-height: 0.36rem;
        text-align: justify;
      }
      .input-line {
        margin: 0.72rem 0;
        height: 0.72rem;
        padding: 2px 2px;
        border-radius: 0.72rem;
        position: relative;
        input {
          flex: 1;
          border: none;
          padding: 0 0.2rem;
          height: 0.72rem;
          font-size: 0.28rem;
        }
        .sumit-btn {
          min-width: 1.52rem;
          height: 0.72rem;
          line-height: 0.72rem;
          padding: 0 12px;
          font-weight: 300;
          font-size: 0.28rem;
        }
      }
      .input-line::after {
        border-radius: 0.72rem;
      }
      .footer-bottom {
        color: #333;
        font-size: 0.24rem;
        line-height: 0.36rem;
      }
    }
  }
  .fixed-kf {
    position: fixed;
    bottom: 2rem;
    right: 0.48rem;
    z-index: 10;
    width: 1rem;
    img {
      width: 1rem;
      height: 1rem;
      margin: 0.1rem 0;
    }
  }
  .notice-bomb {
    width: 80%;
    max-height: 70vh;
    padding: 0 0.36rem;
    padding-bottom: 0.6rem;
    background: url("../assets/images/notice-bg.png") no-repeat top center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    min-height: 20vh;
    overflow: hidden;
    .bomb-shield {
      position: absolute;
      z-index: -1;
      top: -12px;
      left: -10px;
      width: calc(100% + 20px);
      height: calc(100% + 24.54px);
      background: #fff;
      opacity: 0.3;
      backdrop-filter: blur(48px);
      border-radius: 24px;
    }
    .close {
      width: 0.8rem;
      height: 0.8rem;
      text-align: center;
      line-height: 0.8rem;
      position: absolute;
      right: 0;
    }
    .page-logo {
      font-size: 0.48rem;
      text-align: left;
      line-height: 0.8rem;
      padding: 0.48rem 0;
      display: flex;
      img.avatar {
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.2rem;
      }
      .word {
        color: #333;
      }
    }
    .notice-title {
      font-size: 0.6rem;
      color: #333;
      margin-bottom: 0.4rem;
      text-align: center;
    }
    .notice-content {
      flex: 1;
      overflow-y: auto;
      padding: 0.3rem;
      background: #ffffff;
      border: 1.5px solid transparent;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(#fff, #fff),
        linear-gradient(90deg, #5cbffe, #a0f5d0, #ffd7c8);
      border-radius: 0.3rem;
      text-align: justify;
      .title {
        line-height: 0.4rem;
      }
      .sub-title {
        line-height: 0.4rem;
        margin-top: 0.1rem;
      }
      .word {
        line-height: 0.4rem;
      }
    }
    .go-btn {
      width: 100%;
      height: 0.8rem;
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      .word {
        line-height: 0.8rem;
      }
      img {
        width: 0.24rem;
      }
    }
  }
}
</style>
